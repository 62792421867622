import React from "react";

interface AspectRatioProps {
  aspectRatio: number;
  children: React.ReactNode;
  style: React.CSSProperties;
}

export default function AspectRatio({
  aspectRatio,
  children,
  style
}: AspectRatioProps) {
  return (
    <div
      style={{
        ...style,
        paddingTop: `${100 * aspectRatio}%`,
        height: 0,
        position: "relative"
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%"
        }}
      >
        {children}
      </div>
    </div>
  );
}
