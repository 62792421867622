import React from "react";

import { useTranslation } from "react-i18next";

import chroma from "chroma-js";
import Button from "@material-ui/core/Button";
import { Word, Loop, GText, WordIndex } from "./model";
import { useSoftened } from "./utils";

interface EditorProps {
  text: GText;
  setLoop(loop: Loop | null): void;
}

function selectNodeContents(node: Node) {
  const selection = window.getSelection()!;
  selection.removeAllRanges();
  const range = document.createRange();
  range.selectNodeContents(node);
  selection.addRange(range);
}

export default function Editor({ text, setLoop }: EditorProps) {
  const { t } = useTranslation();
  const textareaRef = React.useRef<HTMLDivElement>(null);

  const handleSelectAllClick = React.useCallback(e => {
    if (!textareaRef.current) return;
    textareaRef.current.focus();
    selectNodeContents(textareaRef.current);
    document.execCommand("copy");
  }, []);

  const findSelectedWord = useSoftened(() => {
    const selection = document.getSelection()!;
    const element =
      selection.anchorNode instanceof HTMLElement
        ? selection.anchorNode
        : selection.anchorNode && selection.anchorNode.parentElement;
    if (
      !element ||
      !element.hasAttribute("data-word-index") ||
      !element.hasAttribute("data-result-index")
    )
      return;
    const wordIndex = parseInt(element.getAttribute("data-word-index")!);
    const resultIndex = parseInt(element.getAttribute("data-result-index")!);
    return {
      wordIndex: { wordIndex, resultIndex },
      ...text[resultIndex].alternatives[0].words[wordIndex]
    };
  });

  React.useEffect(() => {
    const handleSelectionChange = () => {
      const word = findSelectedWord();
      if (!word) return;
      console.log("selection around word:", word);
      const { wordIndex, startTime, endTime } = word;
      if (!startTime || !endTime) {
        // TODO: This should never happen, but the app crashes due to incorrect loop sometimes
        console.warn("Incorrect word found", word);
        return;
      }
      setLoop({ wordIndex, startTime, endTime });
    };
    document.addEventListener("selectionchange", handleSelectionChange);
    return () =>
      document.body.removeEventListener(
        "selectionchange",
        handleSelectionChange
      );
  }, []);

  const selectSuspiciousPhrase = React.useCallback(e => {
    const word = findSelectedWord();
    if (!word) return;
    const node = document.querySelector(
      `[data-word-index="${word.wordIndex.wordIndex}"][data-result-index="${
        word.wordIndex.resultIndex
      }"]`
    );
    if (!node) return;
    selectNodeContents(node);
  }, []);

  const handleBlur = React.useCallback(
    e => {
      setLoop(null);
    },
    [setLoop]
  );

  return (
    <>
      <div
        style={{
          width: "100%",
          padding: "0px 5px",
          // Allow for six lines of text
          lineHeight: "1.2em",
          height: "7.2em",
          boxSizing: "border-box"
        }}
        ref={textareaRef}
        contentEditable
        onBlur={handleBlur}
        suppressContentEditableWarning
      >
        {text.map((result, i) => (
          <React.Fragment key={i}>
            {result.alternatives[0].words.map((word, j) => (
              <Word
                word={word}
                key={j}
                wordIndex={{ resultIndex: i, wordIndex: j }}
                onClick={selectSuspiciousPhrase}
              />
            ))}
          </React.Fragment>
        ))}
      </div>
      {text.length > 0 && (
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 40 }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={handleSelectAllClick}
          >
            {t("copy_the_subs")}
          </Button>
        </div>
      )}
    </>
  );
}

function Word({
  wordIndex,
  word: { word, confidence },
  onClick
}: {
  wordIndex: WordIndex;
  word: Word;
  onClick(e: React.MouseEvent): void;
}) {
  const color = chroma
    .mix("red", "#0053af", confidence * confidence, "hsl")
    .hex();
  return (
    <span
      style={{ color }}
      data-result-index={wordIndex.resultIndex}
      data-word-index={wordIndex.wordIndex}
      onClick={onClick}
    >
      {word}{" "}
    </span>
  );
}
