import React from "react";

import { useSpring, config, interpolate } from "react-spring";
import { useDrag } from "react-use-gesture";

// This is a break from full declarativity
// I don't want to remove and add event listeners or restart timers
// Every time a small piece of data they depend on changes
export function useSoftened<T>(fn: () => T) {
  const hardFnRef = React.useRef(fn);
  hardFnRef.current = fn;
  return React.useCallback(() => {
    return hardFnRef.current();
  }, []);
}

// Imperatively change the value for one rendering
// This is used to set the silly reset flag for react-spring imperatively
export function useFlashValue<T>(defaultValue: T) {
  const value = React.useRef<T>(defaultValue);
  const [_, trigger] = React.useState(null);
  function flashValue(t: T) {
    value.current = t;
    trigger(null);
  }
  const flashedValue = value.current;
  value.current = defaultValue;
  return [flashedValue, flashValue] as const;
}

export function useSwiper({
  onNext,
  onPrev,
  hasNext,
  hasPrev
}: {
  onNext(): void;
  hasNext: boolean;
  onPrev(): void;
  hasPrev: boolean;
}) {
  const [{ dragOffsetX }, setDragOffsetX] = useSpring(() => ({
    dragOffsetX: 0,
    config: config.stiff
  }));
  const [resetEnterAnimations, setResetEnterAnimations] = useFlashValue(false);
  const [entering, _setEntering] = React.useState<"left" | "right" | null>(
    null
  );
  const setEntering = React.useCallback(
    (entering: "left" | "right" | null) => {
      entering && setResetEnterAnimations(true);
      _setEntering(entering);
    },
    [_setEntering, setResetEnterAnimations]
  );
  const [lastDragTime, setLastDragTime] = React.useState(0);
  const leftEnter = useSpring({
    from: { x: -500 },
    to: { x: 0 },
    reset: resetEnterAnimations,
    onRest: () => entering === "left" && setEntering(null)
  });
  const rightEnter = useSpring({
    from: { x: 500 },
    to: { x: 0 },
    reset: resetEnterAnimations,
    onRest: () => entering === "right" && setEntering(null)
  });
  const bindDrag = useDrag(
    ({ down, delta: [deltaX], velocities: [velocityX], event, ...rest }) => {
      if (!down && hasPrev && (deltaX > 100 || velocityX > 1.5)) {
        onPrev();
        setEntering("left");
      } else if (!down && hasNext && (deltaX < -100 || velocityX < -1.5)) {
        onNext();
        setEntering("right");
      }
      if (Math.abs(deltaX) > 50) setLastDragTime(Date.now());
      setDragOffsetX({ dragOffsetX: down ? deltaX : 0 });
    }
  );

  const offsetX = interpolate(
    // @ts-ignore
    [dragOffsetX, leftEnter.x, rightEnter.x],
    (x, lE, rE) => {
      return (
        x + (entering === "left" ? lE : 0) + (entering === "right" ? rE : 0)
      );
    }
  );

  const wasDragJustFinished = () => Date.now() - lastDragTime < 25

  return {
    bindDrag,
    setEntering,
    wasDragJustFinished,
    offsetX
  };
}
