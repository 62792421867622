import React from "react";
// import { videoToWav } from "./media";

// @ts-ignore
import exampleData from "./exampleData";

import Editor from "./Editor";
import VideosUploadLoop from "./VideosUploadLoop";
import AspectRatio from "./AspectRatio";

import "./i18n";
import { useTranslation } from "react-i18next";
import { Video, VideoSelection } from "./model";
import SwapLanguageButton from "./SwapLanguageButton";

const backendURL =
  process.env.NODE_ENV === "development"
    ? `http://${window.location.hostname}:9090/analyze`
    : "/analyze";

export default function App() {
  const { t, i18n } = useTranslation();
  const [videos, setVideos] = React.useState<Video[]>([]);
  const [
    videoSelection,
    setVideoSelection
  ] = React.useState<VideoSelection | null>(null);

  const uploadVideos = React.useCallback((files: FileList) => {
    for (const file of Array.from(files)) {
      addVideoFile(file);
    }
  }, []);

  async function addVideoFile(videoFile: File) {
    const src = URL.createObjectURL(videoFile);
    setVideos(srcs => [...srcs, { video: src, text: null }]);
    setVideoSelection(vs => (vs === null ? { src, loop: null } : vs));

    // const formData = new FormData(e.target);
    // const audioArrayBuffer = await videoToWav(formData.get("video"));
    // formData.set("audio", audioArrayBuffer);
    const formData = new FormData();
    formData.append("video", videoFile);
    formData.append("languageCode", i18n.language);
    const res = await fetch(backendURL, {
      method: "POST",
      body: formData
    });
    const { text } = await res.json();

    console.log(text);
    setVideos(videos =>
      videos.map(video => (video.video === src ? { ...video, text } : video))
    );
  }

  React.useEffect(() => {
    const handleKeydown = async (e: KeyboardEvent) => {
      if (e.key === "f" && e.ctrlKey && exampleData.length > 0) {
        setVideos(exampleData);
        setVideoSelection({ src: exampleData[0].video, loop: null });
      }
    };
    const handleBeforeInstallPrompt = (e: any) => {
      console.log("beforeinstallprompt");
    };
    document.body.addEventListener("keydown", handleKeydown);
    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    return () => {
      document.body.removeEventListener("keydown", handleKeydown);
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const selectedVideo =
    videoSelection && videos.find(({ video }) => video === videoSelection.src);
  const loading = selectedVideo && !selectedVideo.text;
  return (
    <div style={{ width: 500 }}>
      {/* <button onClick={loadExampleData}>Load example</button> */}
      {/* <br /> */}
      <AspectRatio aspectRatio={1 / 1} style={{ background: "black" }}>
        <VideosUploadLoop
          videos={videos}
          uploadVideos={uploadVideos}
          setVideoSelection={setVideoSelection}
          videoSelection={videoSelection}
        />
      </AspectRatio>
      <hr />
      {selectedVideo && selectedVideo.text ? (
        <Editor
          text={selectedVideo.text}
          setLoop={loop => setVideoSelection(vs => (vs ? { ...vs, loop } : vs))}
        />
      ) : (
        <div
          style={{
            width: "100%",
            padding: "0px 5px",
            boxSizing: "border-box",
            fontStyle: "italic"
          }}
        >
          {loading ? t("generating_subtitles") : t("we_can_generate_subs")}
        </div>
      )}
      <SwapLanguageButton
        variant="text"
        style={{ position: "absolute", bottom: 0 }}
      />
    </div>
  );
}
