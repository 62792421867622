import React from "react";
import { throttle } from "throttle-debounce";

import Button from "@material-ui/core/Button";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";

import styled from "styled-components";
import { animated, interpolate } from "react-spring";
import { useTranslation } from "react-i18next";

import { Loop, GTime, Video, VideoSelection } from "./model";
import SwapLanguageButton from "./SwapLanguageButton";

import { useSwiper } from "./utils";

function normalizeLoop({ wordIndex, startTime, endTime }: Loop) {
  const getTimeInSecs = (time: GTime) =>
    parseFloat(time.seconds) + time.nanos / 1000000000;
  const start = getTimeInSecs(startTime) - 0.5;
  const end = getTimeInSecs(endTime) + 0.5;
  return { wordIndex, start, end };
}

const RootDiv = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  place-content: center;
  align-items: center;
  border: ${(props: { border: boolean }) =>
    props.border ? "4px dashed white" : "none"};
  box-sizing: border-box;
  /* Some versions of Safari don't support touch-action: none; */
  touch-action: manipulation;
  touch-action: none;
`;

const UploadPrompt = styled.a`
  position: absolute;
  bottom: ${(props: { bottom: boolean }) => (props.bottom ? "0" : "50%")};
  transform: ${(props: { bottom: boolean }) => (props.bottom ? "translate(0,0)" : "translate(0, 50%)")};
  transition: 0.7s;
  z-index: 9999;
  color: white;
  text-decoration: none;
  text-align: center;
  font-size: ${(props: { bottom: boolean }) => (props.bottom ? "1em" : "2em")};
`;

interface VideosUploadLoopProps {
  videos: Video[];
  setVideoSelection(vs: VideoSelection): void;
  videoSelection: VideoSelection | null;
  uploadVideos(files: FileList): void;
}

export default function VideosUploadLoop({
  videos,
  setVideoSelection,
  videoSelection,
  uploadVideos
}: VideosUploadLoopProps) {
  const { t } = useTranslation();
  const { src = "", loop = null } = videoSelection || {};
  const normalizedLoop = loop && normalizeLoop(loop);
  const wordIndex = normalizedLoop && normalizedLoop.wordIndex.wordIndex;
  const resultIndex = normalizedLoop && normalizedLoop.wordIndex.resultIndex;
  const [mustBeUnmuted, setMustBeUnmuted] = React.useState(false);

  const index = videos.findIndex(({ video }) => src === video);
  const prevVideo = videos[index - 1];
  const nextVideo = videos[index + 1];

  const { bindDrag, setEntering, wasDragJustFinished, offsetX } = useSwiper({
    onNext() {
      setVideoSelection({ src: nextVideo.video, loop: null });
    },
    onPrev() {
      setVideoSelection({ src: prevVideo.video, loop: null });
    },
    hasPrev: !!prevVideo,
    hasNext: !!nextVideo
  });

  React.useEffect(() => {
    if (!src) return;
    restartLoopIfNeedBe();
    if (normalizedLoop) {
      console.log(normalizedLoop, { src });
    }
    const playPromise = videoRef.current!.play();
    playPromise.catch(err => {
      console.warn(err);
      // Try playing muted and display the unmute button
      videoRef.current!.muted = true;
      videoRef.current!.play();
      setMustBeUnmuted(true);
    });
  }, [src, wordIndex, resultIndex]);

  const restartLoop = React.useMemo(() => {
    return throttle(2000, start => {
      console.log("Resetting to", start);
      videoRef.current!.currentTime = start;
    });
  }, []);

  function restartLoopIfNeedBe() {
    if (!normalizedLoop) return;
    const { start, end } = normalizedLoop;
    // console.log({ start, end, ct: videoRef.current.currentTime })
    // Sometimes seeking to 3.00001 will actually seek to 3.0 instead
    // To avoid infinite seek loop, allow epsilon error on seek ranges
    const epsilon = 0.001;
    if (
      videoRef.current!.currentTime > end + epsilon ||
      videoRef.current!.currentTime < start - epsilon
    ) {
      restartLoop(start);
    }
  }

  const handleTimeUpdate = React.useCallback(() => {
    // console.log("time update", videoRef.current!.currentTime);
    restartLoopIfNeedBe();
  }, [src, normalizedLoop && normalizedLoop.wordIndex]);

  const videoRef = React.useRef<HTMLVideoElement>(null);

  const handleChange = (e: React.ChangeEvent) => {
    const { files } = e.target as HTMLInputElement;
    uploadVideos(files!);
  };
  const selectedVideo =
    videoSelection && videos.find(({ video }) => video === videoSelection.src);
  const loading = selectedVideo && !selectedVideo.text;

  const rootDivRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const handleTouchMove = (e: TouchEvent) => {
      // This following is to fix the scrolling in Safari (it doesn't support touch-action: none)
      e.preventDefault();
      // e.stopImmediatePropagation()
    };
    rootDivRef.current!.addEventListener("touchmove", handleTouchMove, {
      passive: false
    });
    return () =>
      rootDivRef.current!.removeEventListener("touchmove", handleTouchMove);
  }, []);

  return (
    <RootDiv ref={rootDivRef} border={videos.length === 0} {...bindDrag()}>
      <animated.video
        ref={videoRef}
        style={{
          position: "absolute",
          top: 0,
          left: offsetX,
          width: "100%",
          height: "100%"
        }}
        src={src}
        autoPlay
        loop
        controls={false}
        controlsList="nofullscreen"
        playsInline
        onTimeUpdate={handleTimeUpdate}
        // onPause={() => console.log("pause")}
        // onAbort={() => console.log("abort")}
        // onEnded={() => console.log("ended")}
        // onSeeked={() => console.log("seeked")}
        // onSeeking={() => console.log("seeking")}
        // onStalled={() => console.log("stalled")}
        // onSuspend={() => console.log("suspend")}
      />
      <input
        type="file"
        accept="audio/*,video/*"
        multiple
        onChange={handleChange}
        onClick={e => {
          wasDragJustFinished() && e.preventDefault();
        }}
        style={{
          fontSize: "10000px",
          position: "absolute",
          zIndex: 99999,
          left: 0,
          top: 0,
          opacity: 0,
          cursor: "pointer"
        }}
      />
      {mustBeUnmuted && (
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={() => {
            videoRef.current!.muted = false;
            setMustBeUnmuted(false);
          }}
          style={{
            position: "relative",
            zIndex: 999999
          }}
        >
          <VolumeUpIcon />
        </Button>
      )}
      <UploadPrompt bottom={!loading && !!videoSelection}>
        {!videoSelection && t("add_videos")}
        {!!videoSelection && t("add_more_videos")}
      </UploadPrompt>
      <UploadPrompt bottom={!(!loading && !!videoSelection)} style={{ zIndex: 999999 }}>
        { !(!loading && !!videoSelection) && <SwapLanguageButton />}
      </UploadPrompt>
      {nextVideo && (
        <Button
          variant="contained"
          color="primary"
          aria-label="show next video"
          style={{
            position: "absolute",
            zIndex: 999999,
            right: "5px",
            bottom: "0px"
          }}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            setVideoSelection({ src: nextVideo.video, loop: null });
            setEntering("right");
          }}
        >
          <NavigateNextIcon />
        </Button>
      )}
      {prevVideo && (
        <Button
          variant="contained"
          color="primary"
          aria-label="show prev video"
          style={{
            position: "absolute",
            zIndex: 999999,
            left: "5px",
            bottom: "0px"
          }}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            setVideoSelection({ src: prevVideo.video, loop: null });
            setEntering("left");
          }}
        >
          <NavigateBeforeIcon />
        </Button>
      )}
    </RootDiv>
  );
}
