import React from "react";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";

export default function SwapLanguageButton({
  style,
  variant
}: {
  style?: React.CSSProperties;
  variant?: "text"
}) {
  const { t, i18n } = useTranslation();
  const swapLanguage = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    i18n.changeLanguage(i18n.language.match("en") ? "pl-PL" : "en-US");
  };
  return (
    <Button
      variant={variant || "contained"}
      size="large"
      onClick={swapLanguage}
      style={style}
    >
      {t("swap_language")}
    </Button>
  );
}
