import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      pl: {
        translations: {
          generating_subtitles:
            "Trwa generowanie napisów... Możesz dodać wiecej filmów w międzyczasie",
          we_can_generate_subs:
            'Wygenerujemy napisy do Twojej Instagram story! Naciśnij "+ DODAJ VIDEO" aby zacząć',
          add_videos: "+ DODAJ VIDEO",
          add_more_videos: "+ DODAJ WIĘCEJ VIDEO",
          swap_language: "🇵🇱/🇺🇸",
          copy_the_subs: "SKOPIUJ NAPISY"
        }
      },
      en: {
        translations: {
          generating_subtitles:
            "Generating subtitles... You can add more videos while you wait",
          we_can_generate_subs:
            'We can generate the subtitles for your Instagram story! Tap "+ ADD VIDEOS" to start',
          add_videos: "+ ADD VIDEOS",
          add_more_videos: "+ ADD MORE VIDEOS",
          swap_language: "🇺🇸/🇵🇱",
          copy_the_subs: "COPY THE SUBS"
        }
      }
    },
    fallbackLng: "en",
    debug: true,
    ns: ["translations"],
    defaultNS: "translations"
  });
