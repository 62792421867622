/// <reference no-default-lib="true"/>
/// <reference lib="dom" />

import React from "react";
import ReactDOM from "react-dom";

import App from "./App";

async function registerSW() {
  try {
    const registration = await navigator.serviceWorker.register(
      "service-worker.ts"
    );
    console.log("SW registered with scope:", registration.scope, registration);
  } catch (err) {
    console.error("Registration failed:", err);
  }
}
if ("serviceWorker" in navigator) {
  window.addEventListener("load", registerSW);
}

ReactDOM.render(<App />, document.querySelector("#root"));
